.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  border-radius: 5px;
  position: relative;
  width: 80%;
  max-width: 400px;
}

.modal-title-bar {
  display: flex;
  align-items: center;
  border: none;
  background-color: #e24a90;
  padding-top: 8px;
}

.modal-title {
  flex-grow: 1;
  font-size: 16px;
  padding: 0px 20px;
  color: #fff;
}

.close-button {
  background-color: transparent;
  color: white;
  font-size: 20px;
  cursor: pointer;
  border: none;
  padding: 10px 20px;
}

.modal-message {
  padding: 20px;
  border: none;
  background-color: transparent;
  font-size: 16px;
}

