/* Basic Reset */
* {
  box-sizing: border-box;
}

:root {
  overflow-y:scroll; /* to prevent content from jumping when scrollbar is needed vs not */
  background-color: rgb(249, 249, 249);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333; /* Dark gray text for readability */
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type = 'text'], input[type = 'email'], input[type = 'password'] {
  margin: 10px 0px 10px 0px;
  width: 300px;
  font-size: 16px;
}

input[type = 'radio'], input[type = 'checkbox'] {
  margin: 10px 6px 6px 0px;
  font-size: 16px;
}

label {
  margin: 8px 0px 8px 0px;
  font-size: 16px;
}

h2, h3 {
  margin-top: 6px;
}

.horizontal-line {
  border: 1px solid #ccc;
  margin: 16px 0px;
}

.app {
  display: flex;
}

#sidebar-left {
  flex: 1;
  padding: 8px;
  background-color: rgb(249, 249, 249);
}

#sidebar-right {
  flex: 2;
  padding: 8px;
  background-color: rgb(249, 249, 249);
}

#main-content {
  flex: 3;
  padding: 8px;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  background-color: rgb(249, 249, 249);
}

/* Responsive Design */
@media (max-width: 768px) {
  .container {
      flex-direction: column;
  }

  #sidebar-left, #sidebar-right {
      display: none; /* Hide sidebars on small screens */
  }

  #main-content {
      border: none;
      padding: 8px;
    }
}

.app-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .app-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.page-content {
  margin: 0px;
}

.center {
  margin: 0;
  position: relative;
  left: 50%;
  -ms-transform: translate(-50%);
  transform: translate(-50%);  
  text-align: center;
}

.errorMessage {
  color: red;
  margin: 8px 0px 16px 0px;
}

.form-page {
  display: grid;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
}

.app-button {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #ed7135;
  border: none;
  padding: 6px 12px;
  border-radius: 5px;
  cursor: pointer;
  margin: 2px;
  cursor: pointer;
}

.app-button:hover {
  background-color: #c75723;
}

.dialog-button-bar {
  border-top: 1px solid #ccc;
  margin: 16px 4px;
  padding-top: 12px;
  display: flex;
  justify-content: right;
}

.align-row {
  display: flex; 
  justify-content: flex-start;
}

.margined-div {
  margin: 4px;
}

.feed-message {
  border: 1px solid #ccc;
  padding: 16px;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 16px;
  background-color: rgb(255, 255, 255);
}

.feed-item {
  border: 1px solid #ccc;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);;
}

.memento-container {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
}

.memento {
  padding: 0px;
  border: 0px; 
  border-radius: 5px;
}

.memento-top-info {
  display: flex;
  justify-content: space-between;
  margin: 4px;
  padding-bottom: 4px;
}

.memento-top-info .username {
  align-self: flex-start;
  margin: 4px 2px 4px 10px;
  font-weight: bold;
}

.memento-top-info .date {
  align-self: flex-end;
  margin: 4px 10px 4px 2px;
  color: #999;
  font-size: 13px;
}

.memento-top-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 512px;
  border: 0px;
  cursor: pointer;
}

.memento-title {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0px;
  margin: 10px;
  font-size: 16px;
  font-style: italic;
  font-weight: bold;
}

.feed-item .memento-title {
  cursor: pointer;
  text-decoration:underline;
}

.memento-type {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0px 0px 8px 0px;
  padding: 0px;
  font-size: 16px;
  font-style: italic;
}

.memento-content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 16px;
  font-style: italic;
  margin-left: 10px;
  margin-right: 10px;
  white-space: pre-wrap;
}

.memento-actions-bar {
  display: flex;
  justify-content: center;
  align-items:center;
  font-size: 16px;
  color: #666;
  margin: 4px;
  border-top: 1px solid #ccc;
  padding-top: 4px;
}

.memento-actions-bar a {
  margin: 4px 8px;
  cursor: pointer;
}

.memento-actions-bar img {
  padding-top: 2px;
}

.items-list {
  padding-left: 20px;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.share-button {
  font-size: 16px;
  color: #666;
  background-color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 5px;
  cursor: pointer;
  width: 216px;
  height: 36px;
  margin: 4px;
  text-align: left;
}

.share-button:hover {
  background-color: #e5e5e5;
}

.share-icons {
  vertical-align: middle;
}

.share-text {
  padding-left: 8px;
}

.horiz-spacer {
  margin: 0px 8px;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.indented {
  padding-left: 16px;
}

.disabled {
  pointer-events: none;
  cursor: default;
  color: #222;
  opacity: 0.6;
}
