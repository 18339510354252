.create-bar {
    padding-bottom: 20px;
}

.instructions-container {
    display: flex;
    position: relative;
}

.create-title {
    margin-top: 0px;
    font-size: 20px;
    font-weight: 700;
}

.help-button {
    position: absolute;
    right: 0px;
    font-size: 16px;
    color: #fff;
    background-color: #ed7135;
    border: none;
    margin: 4px;
    padding: 4px 8px;
    border-radius: 5px;
    cursor: pointer;
}
    
.help-button:hover {
    background-color: #c75723;
}

.create-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
}

.create-container .app-button {
    min-height: 45px;
    min-width: 128px;
    flex: 1 1 0px;
}
