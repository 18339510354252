/* Title Bar Styles */
.title-bar {
    display: flex;
    align-items: center;
    background-color: #4a90e2; /* Primary deep sky blue */
    color: white;
    padding: 0px 4px;
}

.app-icon-button {
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    margin: 0;
    padding: 8px 8px 0px 8px;
}

.app-icon {
    height: 32px;
}

.app-name {
    font-size: 24px;
    padding: 0px 8px 0px 8px;
    cursor: pointer;
    margin: 12px 0px 0px 0px;
}

.app-tagline {
    flex-grow: 1;
    font-size: 12px;
    font-style: italic;
    padding: 4px 8px 0px 8px;
    text-align: center;
}

.menu-button {
    background: none;
    border: none;
    color: white;
    font-size: 32px;
    cursor: pointer;
    padding: 0px 8px 0px 0px;
}

.menu-icon {
    height: 32px;
    padding-top: 8px;
}

.faded {
    opacity: 0.6;
}

/* Dropdown Menu Styles */
.dropdown-menu {
    position: absolute;
    top: 50px;
    right: 20px;
    background-color: white;
    box-shadow: 0 8px 16px rgba(0,0,0,0.1);
    border-radius: 4px;
    z-index: 1000;
}

.dropdown-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.dropdown-menu li {
    padding: 12px 16px;
    border-bottom: 1px solid #e5e5e5;
    position: relative;
}

.dropdown-menu li:last-child {
    border-bottom: none;
}

.dropdown-menu li a {
    text-decoration: none;
    color: #5f6a7d;
    display: block;
}

.dropdown-menu li:hover {
    background-color: #e5e5e5;
}

.dropdown-menu li.has-submenu:hover {
    background-color: white;
}

/* Indented submenu styles */
.dropdown-menu li ul {
    list-style: none;
    padding: 0;
    margin: 8px 0px 0px 0px;
    padding-left: 20px; /* Indentation for submenus */
}

.dropdown-menu li ul li {
    padding: 8px 16px;
    border-bottom: 1px solid #e5e5e5;
}

.dropdown-menu li ul li:last-child {
    border-bottom: none;
}

.dropdown-menu li ul li a {
    text-decoration: none;
    color: #5f6a7d;
    display: block;
}

.dropdown-menu li ul li:hover {
    background-color: #e5e5e5;
}
